<template>
	<div>
	  <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
		 <b-spinner variant="primary" label="Text Centered" />
	  </div>
	  <div v-else>
		 <table-dop-options :dopOptions="dopOptions" @editForm="editForm"/>
		 <modal-dop-options @refresh="refresh" />
		 <modal-dop-options-edit  :id="id" @refresh="refresh" />
		</div>
	</div>
 </template>
 <script>
 import ModalDopOptions from '@/views/component/Modal/ModalDopOptions/ModalDopOptions.vue'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
 import ModalDopOptionsEdit from '@/views/component/Modal/ModalDopOptions/ModalDopOptionsEdit.vue'
 import tableDopOptions from '@/views/component/Table/tableDopOptions.vue'
 export default {
	components: {
		ModalDopOptions,
		ToastificationContent,
		ModalDopOptionsEdit,
	  	tableDopOptions
	},
	data() {
	  return {
		showPreloader: false,
		dopOptions: [],
		id: undefined,
	  }
	},
	methods: {
		refresh() {
		this.showPreloader = true
		this.$http
		.get('car-settings/dop-options')
		.then(res => {
			this.dopOptions = res.data
			this.showPreloader = false
		})
      .catch(err => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Ошибка!',
            icon: 'XIcon',
            variant: 'danger',
            text: err.response.data.errors,
          },
        })
      })
    },
	editForm(id) {
		this.id = id
		this.$bvModal.show('editDopOption')
	},
	openFilter(){
		let allRoutes = this.$store.state.draggableTab.tabs
		let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
		let arrayRoutes = []
		allRoutes.forEach(element => {
			arrayRoutes.push(element.path)
		});
		if (arrayRoutes.includes(this.$route.path) == true) {
			this.showPreloader = true
		this.$http
			.get(`${this.$route.name}`, {params: param}).then(res => {
				this.dopOptions = res.data
				this.showPreloader = false
				this.$store.commit('REFRESH_DATA', false)
			})
		}else if(arrayRoutes.includes(this.$route.path) == false){
			this.$bvModal.show(this.$route.name + 'filter')
		}
	  },
	},
	mounted() {
    	this.refresh()
		this.openFilter()
  	},
	computed: {
	  fetchingNewData(){
		 return this.$store.state.refresh.fetchingNewData
	  }
	},
	watch: {
	  fetchingNewData(val){
		   let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
		 	if (val){
			this.$http
			.get(`${this.$route.name}`, {params: params})
			.then(res =>{
			  this.dopOptions = res.data
			  this.$store.commit('pageData/setdataCount', this.dopOptions.length)
			  this.$store.commit('REFRESH_DATA', false)
			})
		 }
	  }
	}
 }
 </script>