
	<template>
		<b-modal
		id="editDopOption"
		title="Изменить"
		size="sm"
		@shown="getDopOption"
		hide-footer
		no-enforce-focus
		:no-close-on-backdrop="true"
		>
		<b-row class="mb-1">
			<b-col cols="12">
				<label for="name">Наименование</label>
				<b-input v-model="dopOption.name" id="name" placeholder="Наименование" />
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="12">
				<label for="category">Категория</label>
				<v-select
				v-model="dopOption.category_car_id"
				placeholder="Категория"
				:reduce="options => options.id"
				label="name"
				:options="category"
				>
				</v-select>
			</b-col>
		</b-row>
		<b-col cols="12">
			<b-form-checkbox v-model="dopOption.is_active" checked="true" name="check-button" switch inline>
				{{ this.dopOption.is_active ? 'Активный' : 'Не активный' }}
				</b-form-checkbox>
		</b-col>
		<b-row class="mb-1 mt-2">
			<b-col class="d-flex justify-content-end">
				<b-button :disabled="submitButtonDisabled || !dopOption.name" @click="update" variant="primary">
				Изменить
				</b-button>
				<b-button @click="closeModal" class="ml-1" variant="danger">Отмена</b-button>
			</b-col>
		</b-row>
		</b-modal>
	</template>
	<script>
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
	import vSelect from 'vue-select'
	export default {
		components: {
		ToastificationContent,
		vSelect,
		},
		props: {
		id: {
			type: Number,
			default: null,
		},
		},
		data() {
		return {
			dopOption: [],
			category: [],
			submitButtonDisabled: false,
		}
		},
		mounted() {
			this.getCategories()
		},
		methods: {
		closeModal() {
			this.dopOption.name = null;
			this.submitButtonDisabled = false;
			this.$bvModal.hide('editDopOption');
		},
		getCategories() {
			this.$http.get('/car-settings/categories')
			.then((res) => {
				this.category = res.data
			})
		},
		getDopOption() {
			this.$http
				.get(`car-settings/dop-options/${this.id}/edit`)
				.then((res) => {
				this.dopOption = res.data;
				this.dopOption.is_active = res.data.is_active === 1 ? true : false;
				})
				.catch((err) => {
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Ошибка!',
						icon: 'XIcon',
						variant: 'danger',
						text: 'Не удалось загрузить дополнительную опцию',
					},
				});
				});
		},
		update() {
			this.submitButtonDisabled = true;
			this.dopOption.is_active = this.dopOption.is_active ? 1 : 0;
			this.$http
				.patch(`car-settings/dop-options/${this.id}`, this.dopOption)
				.then((res) => {
				this.closeModal();
				this.$emit('refresh');
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Доп. опции водителя изменены!',
						icon: 'CheckIcon',
						variant: 'success',
						text: res.data.message,
					},
				});
				})
				.catch((err) => {
				this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Ошибка!',
						icon: 'XIcon',
						variant: 'danger',
						text: 'Некорректные данные',
					},
				});
				})
				.finally(() => {
				this.submitButtonDisabled = false;
				});
		},
		},
	};
	</script>