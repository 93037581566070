<template>
	<b-modal  
		:id="$route.name + 'CreateModal'"
		title="Добавить"
		size="sm"
		hide-footer
		no-enforce-focus
		:no-close-on-backdrop="true"
	>
		<b-row class="mb-1">
			<b-col cols="12">
				<label for="">Наименование</label>
				<b-input v-model="dopOption.name" placeholder="Наименование" />
			</b-col>
		</b-row>
		<b-row class="mb-1">
			<b-col cols="12">
				<label for="category">Категория</label>
				<v-select
				v-model="dopOption.category_car_id"
				placeholder="Категория"
				:reduce="options => options.id"
				label="name"
				:options="category"
				>
				</v-select>
			</b-col>
		</b-row>
		<b-col cols="12">
			<b-form-checkbox v-model="dopOption.is_active" checked="true" name="check-button" switch inline>
				{{ this.dopOption.is_active ? 'Активный' : 'Не активный' }}
				</b-form-checkbox>
		</b-col>
		<b-row class="mb-1 mt-2">
			<b-col class="d-flex justify-content-end">
				<b-button :disabled="submitButtonDisabled || !dopOption.name" @click="createDopOption()" variant="primary">Добавить</b-button>
				<b-button @click="closeModal()" class="ml-1" variant="danger">Отмена</b-button>
			</b-col>
		</b-row>
	</b-modal>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
	components: {
		ToastificationContent,
		vSelect,
	},
	data() {
		return {
			dopOption: {
				name: null,
				category_car_id: null,
				is_active: true
			},
			submitButtonDisabled: false,
			category: [],
		}
	},
	mounted() {
			this.getCategories()
		},
	methods: {
		closeModal() {
			this.dopOption.name = null
			this.submitButtonDisabled = false
			this.$bvModal.hide(this.$route.name + 'CreateModal')
		},
		getCategories() {
			this.$http.get('/car-settings/categories')
			.then((res) => {
				this.category = res.data
			})
		},
		createDopOption() {
			this.submitButtonDisabled = true
			this.$http
				.post('car-settings/dop-options', this.dopOption)
				.then(res => {
					this.closeModal()
					this.$emit('refresh')
					this.$toast({
						component: ToastificationContent,
						position: 'top-right',
						props: {
							title: 'Доп. опция добавлена!',
							icon: 'CheckIcon',
							variant: 'success',
						},
					})
				})
				.catch(err => {
					this.$toast({
						component: ToastificationContent,
						position: 'top-right',
						props: {
							title: 'Некорректные данные!',
							icon: 'XIcon',
							variant: 'danger',
							text: err.response.data.errors,
						},
					})
				})
				.finally(() => {
					this.submitButtonDisabled = false
				})
		},
	}
}
</script>